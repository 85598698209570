import {
  crukTheme,
  UserBlock,
  Text,
  Heading,
  Box,
  IconFa,
  ProgressBar,
} from "@cruk/cruk-react-components";
import { format, formatDistanceToNow } from "date-fns";
import { faClock, faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faShoePrints, faLocationDot } from "@fortawesome/free-solid-svg-icons";

import { Column } from "@fwa/src/components/styles";
import {
  LogoImageWrapper,
  StepsWrapper,
  MarkerWrapper,
  RowWrap,
} from "@fwa/src/components/FeedItemFitbit/styles";

import { type FeedItemActivityFitbitType } from "@fwa/src/types";

const fitbitSVG = "/assets/images/logos/fitbit.svg";

type Props = {
  feedItem: FeedItemActivityFitbitType;
  avatarUrl: string | undefined;
  displayName: string;
  fitbitDailyTargetSteps: number;
};

export const FeedItemFitbit = ({
  feedItem,
  avatarUrl,
  displayName,
  fitbitDailyTargetSteps,
}: Props) => {
  const timeString = formatDistanceToNow(new Date(feedItem.created), {
    addSuffix: true,
    includeSeconds: true,
  });
  const feedItemDateString = format(new Date(feedItem.created), "d LLL y");
  const fitbitPercentage = () =>
    !fitbitDailyTargetSteps
      ? 0
      : Math.floor((feedItem.steps / fitbitDailyTargetSteps) * 100);

  return (
    <div data-component="feed-item-fitbit">
      <Box marginBottom="xs">
        <UserBlock
          name={displayName}
          avatarUrl={avatarUrl}
          extra={
            <Text as="span" textColor="currentColor">
              <IconFa faIcon={faClock} /> {timeString}
            </Text>
          }
          size="m"
        />
      </Box>
      <Heading h3 marginTop="s" textSize="l" marginBottom="none">
        <LogoImageWrapper>
          <img src={fitbitSVG} alt="" />
        </LogoImageWrapper>
        {` Fitbit activity - ${feedItemDateString}`}
      </Heading>
      <Box>
        <RowWrap>
          <ProgressBar
            circleSize="128px"
            barColor={crukTheme.tokenColors.cyan_900}
            circleContents={
              <Column>
                <StepsWrapper>
                  <IconFa faIcon={faShoePrints} />
                </StepsWrapper>
                <Text textAlign="center" marginBottom="none">
                  {feedItem.steps.toLocaleString()}
                </Text>
                <Text textAlign="center">steps</Text>
              </Column>
            }
            percentage={fitbitPercentage()}
            isCircular
          />
          <ProgressBar
            circleSize="128px"
            barColor={crukTheme.tokenColors.cyan_900}
            circleContents={
              <Column>
                <MarkerWrapper>
                  <IconFa faIcon={faLocationDot} />
                </MarkerWrapper>
                <Text textAlign="center" marginBottom="none">
                  {(feedItem.distance / 1000).toFixed(2)}
                </Text>
                <Text textAlign="center">km</Text>
              </Column>
            }
            percentage={fitbitPercentage()}
            isCircular
          />
        </RowWrap>
        {!!fitbitDailyTargetSteps && (
          <Text
            marginTop="s"
            marginBottom="none"
            textColor={
              fitbitPercentage() < 100 ? "initial" : crukTheme.colors.success
            }
          >
            {fitbitPercentage() >= 100 && (
              <>
                <IconFa faIcon={faCheckCircle} />{" "}
              </>
            )}
            {fitbitPercentage()}% of daily target achieved
          </Text>
        )}
      </Box>
    </div>
  );
};

export default FeedItemFitbit;
